<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">{{ title }} Content</h3>

        <form @submit.prevent="storeData()" autocomplete="off" enctype="multipart/form-data">
            <div class="form-group">
                <label for="inputCategoryCreate">Category</label>
                <select class="selectpicker" data-width="100%" data-live-search="true" id="inputCategoryCreate" v-model="input.category_id">
                    <option selected disabled value="">Choose Category</option>
                    <option :value="category.id" v-for="category in categories">{{ category.title }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputTagCreate">Tags</label>
                <select class="selectpicker" data-width="100%" data-live-search="true" id="inputTagCreate" multiple v-model="selectedTags" data-max-options="5">
                    <option disabled value="">Choose Tags</option>
                    <option :value="tag.id" v-for="tag in tags">{{ tag.title }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputTitleCreate">Title</label>
                <input id="inputTitleCreate" type="text" class="form-control" v-model="input.title">
            </div>
            <div class="form-group">
                <label for="inputBodyCreate">Body</label>
                <editor
                    api-key="xzdkce926qvdh8w8qc199lppopaimh48opc0u5jlb4j6ttop"
                    :init="{
                             height: 500,
                             menubar: false,
                             plugins: [
                               'advlist autolink lists link image charmap print preview anchor',
                               'searchreplace visualblocks code fullscreen',
                               'insertdatetime media table paste code help wordcount', 'image', 'code', 'codesample', 'autolink link',
                               'media'
                             ],
                             toolbar:
                               'undo redo | formatselect | bold italic backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | image | code | codesample | link | help | media',
                             codesample_global_prismjs: true,
                             media_live_embeds: true,
                             link_default_protocol: 'https'
                           }"
                    v-model="input.body" class="form-control" id="inputBodyCreate"/>
<!--                <textarea class="form-control" id="inputBodyCreate" cols="30" rows="10" v-model="input.body"></textarea>-->
            </div>
            <div class="form-group">
                <div class="custom-file">
                    <label class="custom-file-label" for="inputFileCreate">Choose file</label>
                    <input type="file" class="custom-file-input" id="inputFileCreate" accept="image/*" @change="handleFileUpload">
                </div>
            </div>
            <div class="form-group" v-if="image !== null">
                <img width="1500" height="720" :src="image" alt="Image Upload" class="img-fluid fit_image">
            </div>
            <button type="submit" class="btn btn-primary">Save</button>
        </form>
    </div>
</template>

<script>
    import { unAuthorize } from '../unauthorize'

    export default {
        data: function () {
            return {
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                id: this.$route.query.id,
                categories: [],
                tags: [],
                selectedTags: [],
                input: {
                    category_id: '',
                    title: '',
                    body: '',
                    file: null,
                    category: {},
                    tags: [],
                },
                title: '',
                image: null,
                isChangeImage: false,
            }
        },
        methods: {
            getData() {
                const headers = this.headers
                axios.get('/api/contents/' + this.id, {headers})
                    .then((res) => {
                        this.input = res.data.data
                        for (var i = this.input.tags.length - 1; i >= 0; i--) {
                            this.selectedTags.push(this.input.tags[i].id)
                        }
                        if (this.input.file !== null) {
                            this.image = '/storage/' + this.input.file
                        }
                        this.getCategories()
                        this.getTags()
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            getCategories() {
                const headers = this.headers
                axios.get('/api/categories-list', {headers})
                    .then((res) => {
                        this.categories = res.data.data
                        this.$nextTick(function () {
                            $('#inputCategoryCreate').selectpicker()
                        })
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            getTags() {
                const headers = this.headers
                axios.get('/api/tags-list', {headers})
                    .then((res) => {
                        this.tags = res.data.data
                        this.$nextTick(function () {
                            $('select').selectpicker()
                        })
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            storeData() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()

                formData.append('category_id', this.input.category_id)
                formData.append('title', this.input.title)
                formData.append('body', this.input.body)
                if (this.selectedTags.length > 0) {
                    for (var i = 0; i < this.selectedTags.length; i++) {
                        formData.append('tags[]', this.selectedTags[i])
                    }
                }
                if (this.input.file !== null && this.isChangeImage) {
                    formData.append('file', this.input.file)
                }
                var url = '/api/contents'
                if (this.id !== undefined) {
                    url = '/api/contents/' + this.input.id
                    formData.append('_method', 'PUT')
                }
                axios.post(url, formData, {headers})
                    .then((res) => {
                        if (this.id !== undefined) {
                            toastr.success('Content Successfully Updated', 'Haris Blog')
                        } else {
                            toastr.success('Content Successfully Created', 'Haris Blog')
                        }
                        loader.hide()
                        this.$router.go(-1)
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg.category_id !== undefined) {
                            toastr.error(msg.category_id[0], 'Validation')
                        }
                        if (msg.title !== undefined) {
                            toastr.error(msg.title[0], 'Validation')
                        }
                        if (msg.body !== undefined) {
                            toastr.error(msg.body[0], 'Validation')
                        }
                        if (msg.tags !== undefined) {
                            toastr.error(msg.tags[0], 'Validation')
                        }
                        if (msg.file !== undefined) {
                            toastr.error(msg.file[0], 'Validation')
                        }
                    })
            },
            handleFileUpload(e) {
                this.isChangeImage = true
                this.input.file = e.target.files[0]
                this.image = URL.createObjectURL(this.input.file)
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            if (this.id !== undefined) {
                this.title = 'Edit'
                this.getData()
            } else {
                this.title = 'Create'
                this.getCategories()
                this.getTags()
            }
        }
    }
</script>
