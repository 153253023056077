<template>
    <div style="background-color: white;">
        <div class="blog_image">
            <a href="#">
                <img width="1500" height="720"
                     src="http://lisbeth.premiumcoding.com/lisbeth-demo5/wp-content/uploads/2017/06/lisbeth-demo1-4-1500x720.jpg"
                     alt="Tentang Saya" class="img-fluid fit_image">
            </a>
        </div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="font_lora font-italic">
                        Tentang Saya
                    </h1>
                </div>
                <div class="col-md-2">
                    <img
                        src="/image/personal_picture.png"
                        alt="About Me" width="100%" class="img-fluid mt-3">
                </div>
                <div class="col-md-10">
                    <p class="about_sub font-italic mt-3">
                        Halo, nama saya adalah <strong>Haris Miftahul Hudha</strong>. Saya seorang Software Developer yang tinggal di Surabaya. Ini adalah blog saya, tempat saya memposting tentang pemrograman, cerita, pendapat pribadi tentang berita terbaru.
                        Saya berkecimpung di dunia Pemrograman sudah lebih dari {{ year }} Tahun.
                    </p>
                    <p class="about_sub font-italic mt-3">
                        Beberapa kata tentang saya dan pekerjaan saya dan bagaimana Anda dapat menghubungi saya atau mengirim pertanyaan. Jadi, silakan menghubungi jika Anda memiliki pertanyaan. Saya terbuka untuk kritik dan saran yang anda berikan.
                    </p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4">
                    <h3 class="font_lora font-italic">Alamat</h3>
                    <p class="about_sub font-italic mt-3">
                        Jl. Pagesangan No. 36 E RT. 02 RW. 08
                        Pagesangan, Surabaya, Jawa Timur, Indonesia, 60233
                    </p>
                </div>
                <div class="col-md-4">
                    <h3 class="font_lora font-italic">Telepon</h3>
                    <p class="about_sub font-italic mt-3">
                        +62 87-855-736-502
                    </p>
                </div>
                <div class="col-md-4">
                    <h3 class="font_lora font-italic">Email</h3>
                    <p class="about_sub font-italic mt-3">
                        me@harismiftahulhudha.co
                    </p>
                </div>
            </div>
            <div class="row mt-4 pb-5">
                <div class="col-md-12">
                    <h3 class="font_lora font-italic">Kontak Melalui Sosial Media</h3>
                </div>
                <div class="col-md-12">
                    <span class="social_about">
                        <a href="https://www.facebook.com/haris.no.system.is.safe" target="_blank"><i class="fa fa-facebook-square"></i></a>
                        <a href="https://www.instagram.com/harismiftahulhudha/" target="_blank"><i class="fa fa-instagram"></i></a>
                        <a href="https://api.whatsapp.com/send?phone=6287855736502&text=Hi%20Haris,%20Nice%20to%20meet%20you." target="_blank"><i class="fa fa-whatsapp"></i></a>
                        <a href="https://www.linkedin.com/in/haris-miftahul-hudha-150027128/" target="_blank"><i class="fa fa-linkedin-square"></i></a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                year: 2016
            }
        },
        methods: {
            //
        },
        mounted() {
            var d = new Date();
            var n = d.getFullYear();
            this.year = n - this.year
        }
    }
</script>
