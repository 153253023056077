<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">Portfolios</h3>
        <form @submit.prevent="searchData()" autocomplete="off">
            <div class="input-group mb-3 font_lora">
                <input id="query" type="text" class="form-control" v-model="query" placeholder="SEARCH AND HIT ENTER...">
                <div class="input-group-append" style="cursor: pointer;" v-on:click="searchData()">
                    <span class="input-group-text" id="search_blog"><i class="fa fa-search"></i></span>
                </div>
            </div>
        </form>
        <router-link class="btn btn-outline-secondary mb-3" :to="{name: 'admin.portfolios.createOrEdit'}">Create Portfolio</router-link>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                    <th scope="col">TITLE</th>
                    <th scope="col">SLUG</th>
                    <th scope="col">#</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="portfolio, index in data">
                    <td>{{ portfolio.title }}</td>
                    <td>{{ portfolio.slug }}</td>
                    <td>
                        <router-link class="btn btn-outline-info" :to="{name: 'admin.portfolios.createOrEdit', query: {id: portfolio.id}}">Edit</router-link>
                        <button type="button" class="btn btn-outline-danger" data-toggle="modal" data-target="#modalDeletePortfolio" v-on:click="getModel(index)">Hapus</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="row" style="justify-content: center;" v-if="pagination.lastPage > 1">
            <nav aria-label="Page Haris Blog">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.firstPage)" aria-label="Previous">
                            <i class="fa fa-step-backward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.prevPage)" aria-label="Previous">
                            <i class="fa fa-backward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{ active: pagination.currentPage === page }" v-for="page in pagination.pages">
                        <a class="page-link" v-on:click="getPagination(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.nextPage)" aria-label="Next">
                            <i class="fa fa-forward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.lastPage)" aria-label="Next">
                            <i class="fa fa-step-forward"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalDeletePortfolio" tabindex="-1" role="dialog" aria-labelledby="modalDeletePortfolioLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalDeletePortfolioLabel">Delete Portfolio</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to delete "<strong>{{ model.title }}</strong>"
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" v-on:click="deleteData()">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../app'
    import { paginate } from '../pagination'
    import { unAuthorize } from '../unauthorize'

    export default {
        data: function () {
            return {
                pagination: {
                    currentPage: 1,
                    firstPage: 1,
                    lastPage: 0,
                    nextPage: 0,
                    prevPage: 0,
                    offsetPage: 0,
                    pages: []
                },
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                data: [],
                query: this.$route.query.query,
                page: this.$route.query.page,
                model: {},
                index: 0
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.query = to.query.query
            this.page = to.query.page
            this.getData()
            next()
        },
        methods: {
            getPagination(page) {
                const params = {}
                if (this.query !== '') {
                    params.query = this.query
                }
                if (page === 1 || page === '1') {
                    this.page = undefined
                } else {
                    this.page = page
                    params.page = page
                }
                this.$router.push({name: "admin.portfolios", query: params})
            },
            searchData() {
                const params = {}
                if (this.query !== '') {
                    params.query = this.query
                }
                this.$router.push({name: "admin.portfolios", query: params})
            },
            getData() {
                const headers = this.headers
                const params = {}
                if (this.page !== undefined) {
                    params.page = this.page
                }
                if (this.query !== undefined) {
                    params.query = this.query
                } else {
                    this.query = ''
                }
                axios.get('/api/portfolios', {params, headers})
                    .then((res) => {
                        this.data = res.data.data
                        if (this.data.length === 0) {
                            this.resetPagination()
                        } else {
                            this.pagination.currentPage = res.data.current_page
                            this.pagination.lastPage = res.data.last_page
                            if (res.data.prev_page_url !== null) {
                                this.pagination.prevPage = res.data.prev_page_url.split("page=")[1]
                            }
                            if (res.data.next_page_url !== null) {
                                this.pagination.nextPage = res.data.next_page_url.split("page=")[1]
                            }
                            this.pagination.pages = paginate(res.data.total, this.pagination.currentPage, res.data.per_page, 5).pages
                        }
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            resetPagination() {
                this.pagination.currentPage = 1
                this.pagination.firstPage = 1
                this.pagination.lastPage = 0
                this.pagination.nextPage = 0
                this.pagination.prevPage = 0
                this.pagination.offsetPage = 0
                this.pagination.pages = []
            },
            convertArrayToString(tags) {
                var join = ''
                for (var i = 0; i < tags.length; i++) {
                    join += tags[i].title
                    if (i < tags.length - 1) {
                        join += ', '
                    }
                }
                return join
            },
            getModel(index) {
                this.index = index
                this.model = this.data[index]
            },
            deleteData() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()
                formData.append('_method', 'DELETE')
                axios.post('/api/portfolios/' + this.model.id, formData, {headers})
                    .then((res) => {
                        toastr.success('Portfolio Successfully Deleted', 'Haris Blog')
                        $('#modalDeletePortfolio').modal('hide')
                        this.data.splice(this.index, 1)
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg !== undefined) {
                            toastr.error(msg, 'Validation')
                        }
                    })
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            this.getData()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
