<template>
    <div class="search mt-4">
        <p class="title font-italic">Pencarian</p>
        <hr class="hr">
        <form @submit.prevent="searchBlog('query', query)" autocomplete="off">
            <div class="input-group mb-3 font_lora">
                <input id="query" type="text" class="form-control" v-model="query" placeholder="CARI DAN TEKAN ENTER...">
                <div class="input-group-append" style="cursor: pointer;" v-on:click="searchBlog('query', query)">
                    <span class="input-group-text" id="search_blog"><i class="fa fa-search"></i></span>
                </div>
            </div>
        </form>
        <hr class="hr_full">
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                query: '',
            }
        },
        methods: {
            searchBlog(type, value) {
                this.$router.push({ name: 'guest.searchBlog', params: {type: type, value: value} })
            }
        },
        mounted() {
            //
        }
    }
</script>
