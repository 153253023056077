<template>
    <div class="tags mt-4">
        <p class="title font-italic">Tag</p>
        <hr class="hr">
        <div v-if="!isLoaded">
            <div class="d-flex align-items-center">
                <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
            </div>
        </div>
        <p v-if="isLoaded">
            <span v-for="tag in tags" class="label" v-on:click="searchBlog('tag', tag.slug)">{{ tag.title }}</span>
        </p>
        <hr class="hr_full">
    </div>
</template>

<script>
    import {unAuthorize} from "../unauthorize";

    export default {
        data: function () {
            return {
                tags: [],
                isLoaded: false,
            }
        },
        methods: {
            searchBlog(type, value) {
                this.$router.push({ name: 'guest.searchBlog', params: {type: type, value: value} })
            },
            getTags() {
                axios.get('/api/guestTags')
                    .then((res) => {
                        this.tags = res.data.data
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
        },
        mounted() {
            this.getTags()
        }
    }
</script>
