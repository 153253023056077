<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">{{ title }} Finance</h3>

        <form @submit.prevent="storeData()" autocomplete="off">
            <div class="form-group">
                <label for="inputCategoryCreate">Category</label>
                <select id="inputCategoryCreate" class="form-control" v-model="input.category">
                    <option :value="category.id" v-for="category in categories">{{ category.id }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputTransactionCreate">Transaction</label>
                <select id="inputTransactionCreate" class="form-control" v-model="input.transaction">
                    <option :value="transaction.id" v-for="transaction in transactions">{{ transaction.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputDescriptionCreate">Description</label>
                <input id="inputDescriptionCreate" type="text" class="form-control" v-model="input.description">
            </div>
            <div class="form-group">
                <label for="inputAmountCreate">Amount</label>
                <input id="inputAmountCreate" type="number" class="form-control" v-model="input.amount">
            </div>
            <button type="submit" class="btn btn-primary">Save</button>
        </form>
    </div>
</template>

<script>
    import { unAuthorize } from '../unauthorize'

    export default {
        data: function () {
            return {
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                id: this.$route.query.id,
                categories: [
                    {id: 'Makanan'},
                    {id: 'Transportasi'},
                    {id: 'Kuliah'},
                    {id: 'Hutang'},
                    {id: 'Grab Food'},
                    {id: 'Internet'},
                    {id: 'Orang Tua'},
                    {id: 'Berlangganan'},
                    {id: 'Arisan'},
                    {id: 'Belanja Online'},
                    {id: 'Obat'},
                    {id: 'Traveling'},
                    {id: 'Modal'},
                    {id: 'Deposito'},
                    {id: 'Gaji Projek'},
                    {id: 'Gaji Kantor'},
                    {id: 'Pemasukan Lainnya'},
                    {id: 'Pengeluaran Lainnya'},
                    {id: 'Endorsement'},
                    {id: 'Self Improvement'},
                    {id: 'Personal Project'},
                ],
                transactions: [
                    {id: '0', text: 'Debit'},
                    {id: '1', text: 'Kredit'}
                ],
                input: {
                    category: 'Makanan',
                    transaction: '0',
                    description: '',
                    amount: ''
                },
                title: '',
            }
        },
        methods: {
            getData() {
                const headers = this.headers
                axios.get('/api/finances/' + this.id, {headers})
                    .then((res) => {
                        this.input = res.data.data
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            storeData() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()

                formData.append('category', this.input.category)
                formData.append('transaction', this.input.transaction)
                formData.append('description', this.input.description)
                formData.append('amount', this.input.amount)
                var url = '/api/finances'
                if (this.id !== undefined) {
                    url = '/api/finances/' + this.input.id
                    formData.append('_method', 'PUT')
                }
                axios.post(url, formData, {headers})
                    .then((res) => {
                        if (this.id !== undefined) {
                            toastr.success('Finance Successfully Updated', 'Haris Blog')
                        } else {
                            toastr.success('Finance Successfully Created', 'Haris Blog')
                        }
                        loader.hide()
                        this.$router.go(-1)
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg.category_id !== undefined) {
                            toastr.error(msg.category_id[0], 'Validation')
                        }
                        if (msg.title !== undefined) {
                            toastr.error(msg.title[0], 'Validation')
                        }
                        if (msg.body !== undefined) {
                            toastr.error(msg.body[0], 'Validation')
                        }
                        if (msg.tags !== undefined) {
                            toastr.error(msg.tags[0], 'Validation')
                        }
                        if (msg.file !== undefined) {
                            toastr.error(msg.file[0], 'Validation')
                        }
                    })
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            if (this.id !== undefined) {
                this.title = 'Edit'
                this.getData()
            } else {
                this.title = 'Create'
            }
        }
    }
</script>
