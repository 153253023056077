<template>
    <div class="recent mt-4">
        <p class="title font-italic">Postingan Terbaru</p>
        <hr class="hr">
        <div v-if="!isLoaded">
            <div class="d-flex align-items-center">
                <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
            </div>
        </div>
        <ul class="list-unstyled" v-if="isLoaded">
            <div class="pointer" v-for="content in recentContents" v-on:click="detailBlog(content.slug)">
                <li class="media mt-3">
                    <img width="30%"
                         :src="`/storage/${content.file}`"
                         class="align-self-start mr-3" :alt="content.title">
                    <div class="media-body">
                        <h5 class="font_lora font-italic">{{ content.title }}</h5>
                        <span class="font_open_sans" style="font-size: 14px; color: #aaa;">{{ convertUTC(content.created_at) }}</span>
                    </div>
                </li>
                <li>
                    <hr class="hr_full mt-4">
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
    import {unAuthorize} from "../unauthorize";
    import moment from 'moment'

    export default {
        data: function () {
            return {
                recentContents: [],
                isLoaded: false,
            }
        },
        props: {
            totalData: Number,
        },
        methods: {
            detailBlog(slug) {
                window.location.href = '/blog/' + slug
                // this.$router.push({ name: 'guest.detailBlog', params: {slug: slug} })
            },
            getRecentContents() {
                const params = {
                    recent: 1,
                    total: this.totalData
                }
                axios.get('/api/guestContents', {params})
                    .then((res) => {
                        this.recentContents = res.data.data
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            convertUTC(date) {
                moment.locale('id');
                var dateFormat = 'MMMM, Do YYYY';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            },
            convertUTCDateOnly(date) {
                moment.locale('id');
                var dateFormat = 'YYYY-M-D';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            }
        },
        mounted() {
            this.getRecentContents()
        }
    }
</script>
