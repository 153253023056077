<template>
    <div>
        <section id="header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-5 mt-5">
                        <router-link :to="{ name: 'guest.searchBlog', params: {type: 'category', value: 'pemrograman'} }">
                            <img
                                src="/image/programming_setup.jpg"
                                alt="Pemrograman" class="img-fluid">
                            <div class="floating_box">
                                <div class="floating_block_text">
                                    <p class="font-italic">Pemrograman</p>
                                    <hr width="20%">
                                </div>
                                <div class="floating_block_lower_text">
                                    <p>Keterampilan</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-5 mt-5">
                        <router-link :to="{ name: 'guest.searchBlog', params: {type: 'category', value: 'apa-yang-kupikirkan'} }">
                            <img
                                src="/image/pieceful_place.jpg"
                                alt="Apa Yang Kupikirkan" class="img-fluid">
                            <div class="floating_box">
                                <div class="floating_block_text">
                                    <p class="font-italic">Apa Yang Kupikirkan</p>
                                    <hr width="20%">
                                </div>
                                <div class="floating_block_lower_text">
                                    <p>Melawan Arus</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 mb-5 mt-5">
                        <a href="/portofolio">
                            <img
                                src="/image/working_space.jpg"
                                alt="Instagram" class="img-fluid">
                            <div class="floating_box">
                                <div class="floating_block_text">
                                    <p class="font-italic">Portfolio</p>
                                    <hr width="20%">
                                </div>
                                <div class="floating_block_lower_text">
                                    <p>Periksa Portofolio Saya</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="content" style="background-color: white;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 p-4" v-if="!isLoaded">
                        <div class="d-flex align-items-center">
                            <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                            <strong>Loading...</strong>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 p-4" v-if="isLoaded">
                        <div class="row" v-if="data.length === 0">
                            <h3 class="font_lora mt-5">Blog Belum Tersedia.</h3>
                        </div>
                        <div v-for="content in data" class="row" v-else>
                            <blog-content :content="content"/>
                        </div>
                        <div class="row" style="justify-content: center;" v-if="pagination.lastPage > 1">
                            <nav aria-label="Page Haris Blog">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.firstPage)" aria-label="Previous">
                                            <i class="fa fa-step-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.prevPage)" aria-label="Previous">
                                            <i class="fa fa-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{ active: pagination.currentPage === page }" v-for="page in pagination.pages">
                                        <a class="page-link" v-on:click="getPagination(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.nextPage)" aria-label="Next">
                                            <i class="fa fa-forward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.lastPage)" aria-label="Next">
                                            <i class="fa fa-step-forward"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div >
                    <div class="col-lg-4 col-md-12 col-sm-12 p-4">
                        <sidebar-blog></sidebar-blog>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { EventBus } from "../app"
    import {paginate} from "../pagination";
    import {unAuthorize} from "../unauthorize";

    export default {
        data: function () {
            return {
                pagination: {
                    currentPage: 1,
                    firstPage: 1,
                    lastPage: 0,
                    nextPage: 0,
                    prevPage: 0,
                    offsetPage: 0,
                    pages: []
                },
                page: this.$route.query.page,
                data: [],
                isLoaded: false,
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.page = to.query.page
            this.getData()
            next()
        },
        methods: {
            getPagination(page) {
                const params = {}
                if (page === 1 || page === '1') {
                    this.page = undefined
                } else {
                    this.page = page
                    params.page = page
                }
                this.$router.push({name: "guest.home", query: params})
            },
            getData() {
                const params = {}
                if (this.page !== undefined) {
                    params.page = this.page
                }
                axios.get('/api/guestContents', {params})
                    .then((res) => {
                        this.data = res.data.data
                        this.isLoaded = true
                        if (this.data.length === 0) {
                            this.resetPagination()
                        } else {
                            this.pagination.currentPage = res.data.current_page
                            this.pagination.lastPage = res.data.last_page
                            if (res.data.prev_page_url !== null) {
                                this.pagination.prevPage = res.data.prev_page_url.split("page=")[1]
                            }
                            if (res.data.next_page_url !== null) {
                                this.pagination.nextPage = res.data.next_page_url.split("page=")[1]
                            }
                            this.pagination.pages = paginate(res.data.total, this.pagination.currentPage, res.data.per_page, 5).pages
                        }
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
        },
        mounted() {
            this.getData()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
