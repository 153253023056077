<template>
    <div class="blog_content">
        <div class="blog_header pt-3 pb-3">
            <div class="blog_header_category">
                <a href="/">Blog</a>
                <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>
                <router-link :to="{ name: 'guest.searchBlog', params: {type: 'category', value: content.category.slug} }">{{ content.category.title }}</router-link>
            </div>
            <h2>
                <a :href="'/blog/' + content.slug" class="font-italic">{{ content.title }}</a>
            </h2>
        </div>
        <div class="blog_image" v-if="content.file !== null">
            <a :href="'/blog/' + content.slug">
                <img width="1500" height="720"
                     :src="`/storage/${content.file}`"
                     :alt="content.title" class="img-fluid">
            </a>
        </div>
        <div class="blog_meta pt-3 pb-3">
            <router-link :to="{ name: 'guest.searchBlog', params: {type: 'createdAt', value: convertUTCDateOnly(content.created_at)} }" class="font-italic">{{ convertUTC(content.created_at) }}</router-link>
            <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>
            <router-link :to="{ name: 'guest.aboutMe' }" class="font-italic">Oleh Haris Miftahul Hudha</router-link>
<!--            <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>-->
<!--            <router-link :to="{ name: 'guest.detailBlog', params: {slug: 'paradigma-anak-tunggal'}, query: {goTo: 'comments'} }" class="font-italic">7 Comments</router-link>-->
        </div>
        <div class="blog_body" v-if="content.body.substring(0, 3) !== '<p>'">
            <p>
                {{ convertBody(content.body) }}
            </p>
        </div>
        <div class="blog_body" v-else>
            <span v-html="convertBody(content.body)"></span>
        </div>
        <div class="blog_button">
            <a :href="'/blog/' + content.slug" class="btn font-italic">Lanjutkan Membaca</a>
        </div>
        <div class="blog_footer">
            <hr style="background: #eeeeee;">
            <p class="font-italic">
                Bagikan :
                <social-sharing :url="'https://harismiftahulhudha.co/blog/' + content.slug"
                                :title="content.title"
                                :description="shareBody(content.body)"
                                :hashtags="convertArrayToString(content.tags)"
                                twitter-user="vuejs"
                                :media="'storage/' + content.file"
                                inline-template>
                                            <span class="social">
                                                <network network="facebook">
                                                    <i class="fa fa-facebook-square"></i>
                                                </network>
                                                <network network="linkedin">
                                                    <i class="fa fa-linkedin-square"></i>
                                                </network>
                                                <network network="pinterest">
                                                    <i class="fa fa-pinterest"></i>
                                                </network>
                                                <network network="telegram">
                                                    <i class="fa fa-telegram"></i>
                                                </network>
                                                <!--                                                <network network="twitter">-->
                                                <!--                                                    <i class="fa fa-twitter"></i>-->
                                                <!--                                                </network>-->
                                                <network network="whatsapp">
                                                    <i class="fa fa-whatsapp"></i>
                                                </network>
                                            </span>
                </social-sharing>
            </p>
            <hr style="background: #eeeeee;">
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data: function () {
            return {
                //
            }
        },
        props: {
            content: Object,
        },
        methods: {
            detailBlog(slug) {
                this.$router.push({ name: 'guest.detailBlog', params: {slug: slug} })
            },
            convertArrayToString(tags) {
                var join = ''
                for (var i = 0; i < tags.length; i++) {
                    join += tags[i].title
                    if (i < tags.length - 1) {
                        join += ','
                    }
                }
                return join
            },
            convertBody(body) {
                var arrayBody = body.split('</p>')
                if (arrayBody.length > 2) {
                    var p1 = arrayBody[0] + '</p>'
                    var p2 = arrayBody[1] + '</p>'
                    return p1 + p2
                } else {
                    if (body.length > 1000) {
                        body = body.substring(0, 999)
                    }
                    return body
                }
            },
            shareBody(body) {
                var arrayBody = body.split('</p>')
                return arrayBody[0].substr(3, arrayBody[0].length)
            },
            convertUTC(date) {
                moment.locale('id');
                var dateFormat = 'MMMM, Do YYYY';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            },
            convertUTCDateOnly(date) {
                moment.locale('id');
                var dateFormat = 'YYYY-M-D';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            }
        },
        mounted() {
            //
        }
    }
</script>
