<template>
    <div class="card mt-5">
        <img :src="'/storage/' + content.file" class="card-img-top" :alt="content.title">
        <div class="card-body">
            <h5 class="card-title">{{ content.title }}</h5>
            <p><strong>{{ content.category.title }}</strong> - {{ convertUTC(content.created_at) }}</p>
            <p class="card-text"><span v-html="truncate(content.body, 100)"></span></p>
            <div class="blog_button">
                <a :href="'/blog/' + content.slug" class="btn font-italic">Lanjutkan Membaca</a>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data: function () {
            return {
                //
            }
        },
        props: {
            content: Object,
        },
        methods: {
            truncate(value, limit) {
                if (value.length > limit) {
                    value = value.substring(0, (limit - 3)) + '...';
                }

                return value
            },
            convertUTC(date) {
                moment.locale('id');
                var dateFormat = 'MMMM, Do YYYY';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            },
            convertUTCDateOnly(date) {
                moment.locale('id');
                var dateFormat = 'YYYY-M-D';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            }
        },
        mounted() {
            //
        }
    }
</script>
