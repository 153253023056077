<template>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm navbar-custom">
        <a class="navbar-brand" v-bind:class="{ active: url === '/' }" href="/">
            <img src="/image/logo2_full_black.png" alt="logo" width="150" height="62" class="show_on_large">
            <img src="/image/logo2_black.png" alt="logo" width="72" height="60" class="show_on_small">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- Left Side Of Navbar -->
            <ul class="navbar-nav mr-auto">

            </ul>

            <!-- Right Side Of Navbar -->
            <ul class="navbar-nav ml-auto">
                <!-- Authentication Links -->
                <li class="nav-item" v-bind:class="{ active: url === '/blog' }">
                    <a class="nav-link" href="/blog">Blog</a>
                </li>
                <li class="nav-item" v-bind:class="{ active: url === '/portofolio' }">
                    <a class="nav-link" href="/portofolio">Portofolio</a>
                </li>
                <li class="nav-item" v-bind:class="{ active: url === '/about-me' }">
                    <router-link class="nav-link" :to="{name: 'guest.aboutMe' }">Tentang Saya</router-link>
                </li>
<!--                <li class="nav-item" v-bind:class="{ active: url === '/login' }" v-if="token === null || token === undefined">-->
<!--                    <router-link class="nav-link" :to="{name: 'auth.login' }">Login</router-link>-->
<!--                </li>-->
                <li class="nav-item dropdown" v-if="token !== null">
                    <a class="nav-link dropdown-toggle" v-bind:class="{ active: url === '/admin/finances' || url === '/admin/finances/createOrEdit' || url === '/admin/finances/dashboard' }" href="#" id="navbarFinance" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Finance
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarFinance">
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/finances' }" :to="{name: 'admin.finances' }">Finance</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/finances/dashboard' }" :to="{name: 'admin.finances.dashboard' }">Finance Dashboard</router-link>
                    </div>
                </li>
                <li class="nav-item dropdown" v-if="token !== null">
                    <a class="nav-link dropdown-toggle" v-bind:class="{ active: url === '/admin/blogViews' || url === '/admin/visitors' }" href="#" id="navbarVisitor" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Visitor
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarVisitor">
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/visitors' }" :to="{name: 'admin.visitors' }">Visitors</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/blogViews' }" :to="{name: 'admin.blogViews' }">Blog Views</router-link>
                    </div>
                </li>
                <li class="nav-item dropdown" v-if="token !== null">
                    <a class="nav-link dropdown-toggle" v-bind:class="{ active: url === '/admin/categories' || url === '/admin/tags' || url === '/admin/contents' || url === '/admin/subscribers' || url === '/admin/portfolios' }" href="#" id="navbarMaster" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Master
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarMaster">
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/categories' }" :to="{name: 'admin.categories' }">Category</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/tags' }" :to="{name: 'admin.tags' }">Tag</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/contents' }" :to="{name: 'admin.contents' }">Content</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/subscribers' }" :to="{name: 'admin.subscribers' }">Subscriber</router-link>
                        <router-link class="dropdown-item" v-bind:class="{ active: url === '/admin/portfolios' }" :to="{name: 'admin.portfolios' }">Portfolio</router-link>
                    </div>
                </li>
                <li class="nav-item dropdown" v-if="token !== null">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarAdmin" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Admin
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarAdmin">
                        <a class="dropdown-item" href="#">Setting</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" v-on:click="logout()" style="cursor: pointer;">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import { EventBus } from '../app'

    export default {
        data: function () {
            return {
                token: this.$store.getters.token,
                url: this.$route.path,
                unAuthorize: false,
            }
        },
        created() {
        },
        methods: {
            onLoggedIn() {
                EventBus.$on('loggedIn', data => {
                    this.unAuthorize = false
                    this.token = data
                })
            },
            onCheckMenu() {
                EventBus.$on('checkMenu', data => {
                    this.url = data
                })
            },
            onUnAuthorize() {
                EventBus.$on('unAuthorization', data => {
                    if (!this.unAuthorize) {
                        this.unAuthorize = data
                        this.logout('unAuthorize')
                    }
                })
            },
            logout(type = 'default') {
                let loader = this.loading()
                var headers = {
                    Authorization: this.token
                }
                axios.get('/api/logout', {headers})
                    .then((res) => {
                        loader.hide()
                        this.token = null
                        toastr.success('Selamat Tinggal Tuan', 'Haris Blog')
                        this.$store.commit('setToken', null)
                        this.$cookies.remove('token')
                        this.$router.push({ name: 'auth.login' })
                    })
                    .catch((err) => {
                        loader.hide()
                        this.token = null
                        this.$store.commit('setToken', null)
                        this.$cookies.remove('token')
                        this.$router.push({ name: 'auth.login' })
                        toastr.error('Token Tidak Berlaku !', 'Haris Blog')
                    })
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                return loader;
            },
        },
        mounted() {
            if (this.token === undefined) {
                this.token = null
            }
            this.onLoggedIn()
            this.onCheckMenu()
            this.onUnAuthorize()
        }
    }
</script>
