<template>
    <div>
        <div class="subscribe mt-5">
            <div class="shadow bg-white rounded">
                <p class="title font-italic">Berlanggan Blog Haris</p>
                <hr class="hr">
                <div class="blog_button shadow-sm">
                    <form @submit.prevent="subscribe()" autocomplete="off">
                        <input type="text" name="email" v-model="email" placeholder="Email" autocomplete="off">
                    </form>
                </div>
                <div class="blog_button">
                    <a class="btn" v-on:click="subscribe()">LANGGANAN</a>
                </div>
            </div>
        </div>
        <div class="subscribe mt-5">
            <p style="text-align: center;">
                <span class="social">
                    <a href="https://www.facebook.com/haris.no.system.is.safe" target="_blank"><i class="fa fa-facebook-square"></i></a>
                    <a href="https://www.instagram.com/harismiftahulhudha/" target="_blank"><i class="fa fa-instagram"></i></a>
                    <a href="https://api.whatsapp.com/send?phone=6287855736502&text=Hi%20Haris,%20Nice%20to%20meet%20you." target="_blank"><i class="fa fa-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/in/haris-miftahul-hudha-150027128/" target="_blank"><i class="fa fa-linkedin-square"></i></a>
                </span>
            </p>
            <hr class="hr_full">
        </div>
    </div>
</template>

<script>

    import {unAuthorize} from "../unauthorize";

    export default {
        data: function () {
            return {
                email: '',
            }
        },
        methods: {
            subscribe() {
                let loader = this.loading()
                let formData = new FormData()

                formData.append('email', this.email)
                axios.post('/api/guestSubscribers', formData)
                    .then((res) => {
                        this.email = ''
                        toastr.success('Thank you for Subscribing', 'Haris Blog')
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg.email !== undefined) {
                            toastr.error(msg.email[0], 'Validation')
                        }
                    })
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            //
        }
    }
</script>
