<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">{{ title }} Portfolio</h3>

        <form @submit.prevent="storeData()" autocomplete="off" enctype="multipart/form-data">
            <div class="form-group">
                <label for="inputTitleCreate">Title</label>
                <input id="inputTitleCreate" type="text" class="form-control" v-model="input.title">
            </div>
            <div class="form-group">
                <label for="inputBodyCreate">Body</label>
                <editor
                    api-key="xzdkce926qvdh8w8qc199lppopaimh48opc0u5jlb4j6ttop"
                    :init="{
                             height: 500,
                             menubar: false,
                             plugins: [
                               'advlist autolink lists link image charmap print preview anchor',
                               'searchreplace visualblocks code fullscreen',
                               'insertdatetime media table paste code help wordcount'
                             ],
                             toolbar:
                               'undo redo | formatselect | bold italic backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | help'
                           }"
                    v-model="input.description" class="form-control" id="inputBodyCreate"/>
            </div>
            <button type="submit" class="btn btn-primary">Save</button>
        </form>

        <div class="mt-4" v-if="id !== undefined">
            <form @submit.prevent="storeDataImage()" autocomplete="off" enctype="multipart/form-data">
                <div class="form-group">
                    <div class="custom-file">
                        <label class="custom-file-label" for="inputFileCreate">Choose file</label>
                        <input type="file" class="custom-file-input" id="inputFileCreate" accept="image/*" @change="handleFileUpload">
                    </div>
                </div>
                <div class="form-group" v-if="image !== null">
                    <img width="1500" height="720" :src="image" alt="Image Upload" class="img-fluid fit_image">
                </div>
                <button type="submit" class="btn btn-primary">Save</button>
            </form>

            <table class="table table-hover mt-4">
                <thead class="thead-dark">
                <tr>
                    <th scope="col">IMAGE</th>
                    <th scope="col">#</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="image, index in input.portfolio_images">
                    <td>
                        <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#modalPicture" v-on:click="getModel(index)">
                            Picture
                        </button>
                    </td>
                    <td>
                        <button type="button" class="btn btn-outline-danger" data-toggle="modal" data-target="#modalDeletePortfolio" v-on:click="getModel(index)">Hapus</button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="modal fade" id="modalPicture" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" v-if="index !== -1">
                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-body mb-0 p-0">
                            <img :src="`/storage/${input.portfolio_images[index].file}`" :alt="input.title" class="img-fluid fit_image">
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4 text-center" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalDeletePortfolio" tabindex="-1" role="dialog" aria-labelledby="modalDeletePortfolioLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalDeletePortfolioLabel">Delete Image Porfolio</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to delete ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" v-on:click="deleteDataImage()">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { unAuthorize } from '../unauthorize'

    export default {
        data: function () {
            return {
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                id: this.$route.query.id,
                input: {
                    title: '',
                    description: '',
                    portfolio_images: []
                },
                title: '',
                image: null,
                file: null,
                index: -1
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.id = to.query.id
            this.title = 'Edit'
            this.getData()
            next()
        },
        methods: {
            getData() {
                const headers = this.headers
                axios.get('/api/portfolios/' + this.id, {headers})
                    .then((res) => {
                        this.input = res.data.data
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            storeData() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()

                formData.append('title', this.input.title)
                formData.append('description', this.input.description)
                var url = '/api/portfolios'
                if (this.id !== undefined) {
                    url = '/api/portfolios/' + this.input.id
                    formData.append('_method', 'PUT')
                }
                axios.post(url, formData, {headers})
                    .then((res) => {
                        if (this.id !== undefined) {
                            toastr.success('Portfolio Successfully Updated', 'Haris Blog')
                            this.$router.go(-1)
                        } else {
                            toastr.success('Portfolio Successfully Created', 'Haris Blog')
                            this.$router.push({ name: 'admin.portfolios.createOrEdit', query: {id: res.data.data.id} })
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg.title !== undefined) {
                            toastr.error(msg.title[0], 'Validation')
                        }
                        if (msg.description !== undefined) {
                            toastr.error(msg.description[0], 'Validation')
                        }
                    })
            },
            storeDataImage() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()

                formData.append('file', this.file)
                var url = '/api/portfolios/' + this.id + '/images'
                axios.post(url, formData, {headers})
                    .then((res) => {
                        this.image = null
                        this.file = null
                        this.index = -1
                        toastr.success('Portfolio Image Successfully Created', 'Haris Blog')
                        this.input.portfolio_images.push(res.data.data)
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg.file !== undefined) {
                            toastr.error(msg.file[0], 'Validation')
                        }
                    })
            },
            handleFileUpload(e) {
                this.file = e.target.files[0]
                this.image = URL.createObjectURL(this.file)
            },
            deleteDataImage() {
                const headers = this.headers
                let loader = this.loading()
                let formData = new FormData()
                formData.append('_method', 'DELETE')
                axios.post('/api/portfolios/' + this.id + '/images/' + this.input.portfolio_images[this.index].id, formData, {headers})
                    .then((res) => {
                        toastr.success('Portfolio Image Successfully Deleted', 'Haris Blog')
                        $('#modalDeletePortfolio').modal('hide')
                        this.input.portfolio_images.splice(this.index, 1)
                        this.index = -1
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        unAuthorize(err)
                        var msg = err.response.data.msg
                        if (msg !== undefined) {
                            toastr.error(msg, 'Validation')
                        }
                    })
            },
            getModel(index) {
                this.index = index
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            if (this.id !== undefined) {
                this.title = 'Edit'
                this.getData()
            } else {
                this.title = 'Create'
            }
        }
    }
</script>
