<template>
    <div class="container">
        <div class="row pt-5 pb-5">
            <div class="col-md-8 offset-md-2 col-sm-12">
                <form @submit.prevent="login" autocomplete="off">
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text" class="form-control" v-model="username" id="username" autocomplete="off">
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" v-model="password" id="password">
                    </div>
                    <button type="submit" class="btn btn-primary">LOGIN</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../app'
    import { Encryption } from "../encryption"

    export default {
        data: function () {
            return {
                token: '',
                username: '',
                password: ''
            }
        },
        created() {
        },
        methods: {
            login() {
                let loader = this.loading()
                let encryption = new Encryption()
                var model = {
                    username: encryption.encrypt(this.username, process.env.MIX_HARIS_SECRET_KEY_ENCRYPTION),
                    password: encryption.encrypt(this.password, process.env.MIX_HARIS_SECRET_KEY_ENCRYPTION)
                }
                axios.post('/api/login', model)
                    .then((res) => {
                        loader.hide()
                        toastr.success('Selamat Datang Tuan', 'Haris Blog')
                        this.$store.commit('setToken', res.data.data.token)
                        this.$cookies.set('token', 'Bearer ' + res.data.data.token)
                        EventBus.$emit('loggedIn', this.$store.getters.token)
                        this.$router.push({ name: 'admin.finances' })
                    })
                    .catch((err) => {
                        loader.hide()
                        const msg = err.response.data.msg
                        if (msg.username !== undefined) {
                            toastr.error(msg.username[0], 'Username')
                        }
                        if (msg.password !== undefined) {
                            toastr.error(msg.password[0], 'Password')
                        }
                        if (msg.user !== undefined) {
                            toastr.error(msg.user[0], 'Failed')
                        }
                    })
            },
            loading() {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel
                });
                return loader;
            },
        },
        mounted() {
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
