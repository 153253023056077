/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

window.Vue = require('vue')

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('nav-component', require('./components/NavComponent.vue').default)
Vue.component('root-component', require('./components/RootComponent.vue').default)
Vue.component('sidebar-blog', require('./guest/SidebarBlog.vue').default)
Vue.component('sidebar-blog-recent-posts', require('./guest/SidebarBlogRecentPosts.vue').default)
Vue.component('sidebar-blog-categories', require('./guest/SidebarBlogCategories.vue').default)
Vue.component('sidebar-blog-tags', require('./guest/SidebarBlogTags.vue').default)
Vue.component('sidebar-blog-popular-posts', require('./guest/SidebarBlogPopularPosts.vue').default)
Vue.component('sidebar-blog-search', require('./guest/SidebarBlogSearch.vue').default)
Vue.component('sidebar-blog-subscribe-home', require('./guest/SidebarBlogSubscribeHome.vue').default)
Vue.component('sidebar-blog-subscribe-detail', require('./guest/SidebarBlogSubscribeDetail.vue').default)
Vue.component('sidebar-blog-about-me', require('./guest/SidebarBlogAboutMe.vue').default)
Vue.component('sidebar-detail-blog', require('./guest/SidebarDetailBlog.vue').default)
Vue.component('blog-content', require('./guest/BlogContent.vue').default)
Vue.component('editor', require('@tinymce/tinymce-vue').default)
Vue.component('line-chart', require('./chart/LineChart').default)
Vue.component('bar-chart', require('./chart/BarChart').default)
Vue.component('blog-row', require('./guest/BlogRow').default)
Vue.component('portofolio-row', require('./guest/PortofolioRow').default)
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import router from './router'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(Loading)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('12h')

import SocialSharing from 'vue-social-sharing'
Vue.use(SocialSharing)

import VueDisqus from 'vue-disqus'
Vue.use(VueDisqus)

import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'

window.toastr = require('toastr')

Vue.use(VueToastr2)

export const EventBus = new Vue()

import store from './store'

const app = new Vue({
    router,
    store,
    el: '#app',
})
