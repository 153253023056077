<template>
    <div>
        <section id="header_search">
            <div class="container pt-3 pb-3">
                <p class="title">Menjelajahi {{ type }}</p>
                <h3 class="font-italic">{{ value }}</h3>
            </div>
        </section>
        <section id="content_search" style="background-color: white;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 p-4">
                        <div class="row mt-5" v-if="!isLoaded">
                            <div class="d-flex align-items-center">
                                <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                                <strong>Loading...</strong>
                            </div>
                        </div>
                        <div v-if="isLoaded && data.length === 0" class="row">
                            <h3 class="font_lora mt-5">Tidak ada yang ditemukan untuk istilah pencarian ini!</h3>
                        </div>
                        <div v-if="isLoaded && data.length > 0" v-for="content in data" class="row">
                            <blog-content :content="content"/>
                        </div>
                        <div class="row" style="justify-content: center;" v-if="pagination.lastPage > 1">
                            <nav aria-label="Page Haris Blog">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.firstPage)" aria-label="Previous">
                                            <i class="fa fa-step-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.prevPage)" aria-label="Previous">
                                            <i class="fa fa-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{ active: pagination.currentPage === page }" v-for="page in pagination.pages">
                                        <a class="page-link" v-on:click="getPagination(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.nextPage)" aria-label="Next">
                                            <i class="fa fa-forward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.lastPage)" aria-label="Next">
                                            <i class="fa fa-step-forward"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 p-4">
                        <sidebar-blog></sidebar-blog>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {paginate} from "../pagination";
    import {unAuthorize} from "../unauthorize";
    import {convertUTCDateToLocalDate, convertUTCDateToLocalDateOnly} from "../convertUTC";

    export default {
        data: function () {
            return {
                pagination: {
                    currentPage: 1,
                    firstPage: 1,
                    lastPage: 0,
                    nextPage: 0,
                    prevPage: 0,
                    offsetPage: 0,
                    pages: []
                },
                data: [],
                page: this.$route.query.page,
                type: this.$route.params.type,
                value: this.$route.params.value,
                isLoaded: false,
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.$route.params.type = to.params.type
            this.$route.params.value = to.params.value
            this.type = to.params.type
            this.value = to.params.value
            this.page = to.query.page
            this.capitalized()
            this.getData()
            next()
        },
        methods: {
            getPagination(page) {
                const params = {}
                if (page === 1 || page === '1') {
                    this.page = undefined
                } else {
                    this.page = page
                    params.page = page
                }
                this.$router.push({name: "guest.searchBlog", params: {type: this.type, value: this.value}, query: params})
            },
            getData() {
                const params = {}
                if (this.type !== undefined) {
                    params.type = this.$route.params.type
                }
                if (this.value !== undefined) {
                    params.value = this.$route.params.value
                }
                if (this.page !== undefined) {
                    params.page = this.page
                }
                axios.get('/api/guestContents', {params})
                    .then((res) => {
                        this.data = res.data.data
                        this.isLoaded = true
                        if (this.data.length === 0) {
                            this.resetPagination()
                        } else {
                            this.pagination.currentPage = res.data.current_page
                            this.pagination.lastPage = res.data.last_page
                            if (res.data.prev_page_url !== null) {
                                this.pagination.prevPage = res.data.prev_page_url.split("page=")[1]
                            }
                            if (res.data.next_page_url !== null) {
                                this.pagination.nextPage = res.data.next_page_url.split("page=")[1]
                            }
                            this.pagination.pages = paginate(res.data.total, this.pagination.currentPage, res.data.per_page, 5).pages
                        }
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            capitalized() {
                if (this.$route.params.type === 'createdAt') {
                    this.type = this.type.charAt(0).toUpperCase() + this.type.slice(1)
                } else {
                    var val = this.value.split("-")
                    var newVal = ''
                    for (var i = 0; i < val.length; i++) {
                        const data = val[i]
                        const dataCapilatized = data.charAt(0).toUpperCase() + data.slice(1)
                        newVal += dataCapilatized
                        if (i !== val.length - 1) {
                            newVal += ' '
                        }
                    }
                    this.value = newVal
                    this.type = this.type.charAt(0).toUpperCase() + this.type.slice(1)
                }
            }
        },
        mounted() {
            this.capitalized()
            this.getData()
        }
    }
</script>
