<template>
    <div class="categories mt-4">
        <p class="title font-italic">Kategori</p>
        <hr class="hr">
        <div v-if="!isLoaded">
            <div class="d-flex align-items-center">
                <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                <strong>Loading...</strong>
            </div>
        </div>
        <ul class="list-group list-group-flush" v-if="isLoaded">
            <li class="list-group-item pointer pl-0 pr-0" v-for="category in categories" v-on:click="searchBlog('category', category.slug)">{{ category.title }}</li>
            <li class="list-group-item pointer pl-0 pr-0"><span style="visibility: hidden">hidden</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import {unAuthorize} from "../unauthorize";

    export default {
        data: function () {
            return {
                categories: [],
                isLoaded: false
            }
        },
        methods: {
            searchBlog(type, value) {
                this.$router.push({ name: 'guest.searchBlog', params: {type: type, value: value} })
            },
            getCategories() {
                axios.get('/api/guestCategories')
                    .then((res) => {
                        this.categories = res.data.data
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
        },
        mounted() {
            this.getCategories()
        }
    }
</script>
