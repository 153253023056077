<template>
    <div>
        <nav-component></nav-component>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {

            }
        },
        created() {
        },
        methods: {
        },
        mounted() {
            //
        }
    }
</script>
