<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">Blog Viewers</h3>
        <line-chart :chartdata="chartData" :options="chartOptions" v-if="isLoaded"/>
    </div>
</template>

<script>
    import { EventBus } from '../app'
    import { unAuthorize } from "../unauthorize";

    export default {
        data: function () {
            return {
                chartData: null,
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                isLoaded: false,
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
            }
        },
        methods: {
            getData() {
                const headers = this.headers
                axios.get('/api/blogViews', {headers})
                    .then((res) => {
                        const chartData = {
                            labels: [],
                            datasets: [
                                {
                                    label: 'User Viewers',
                                    backgroundColor: '#c29856',
                                    data: []
                                }
                            ]
                        }

                        chartData.labels.push("kosong")
                        chartData.datasets[0].data.push(0)

                        for (let i = 0; i < res.data.length; i++) {
                            const data = res.data[i]
                            chartData.labels.push(data.title)
                            chartData.datasets[0].data.push(data.blog_views_count)
                        }
                        this.chartData = chartData
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            }
        },
        mounted() {
            this.getData()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
