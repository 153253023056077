<template>
    <div class="sidebar">
        <sidebar-blog-subscribe-detail/>
        <sidebar-blog-recent-posts :total-data="3"/>
        <sidebar-blog-categories/>
        <sidebar-blog-popular-posts :total-data="3"/>
        <sidebar-blog-tags/>
        <sidebar-blog-search/>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                //
            }
        },
        methods: {
            //
        },
        mounted() {
            //
        }
    }
</script>
