<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">Finances Dashboard</h3>
        <form @submit.prevent="searchData()" autocomplete="off">
            <div class="form-group">
                <label for="inputTransactionCreate">Transaction</label>
                <select id="inputTransactionCreate" class="form-control" v-model="transaction">
                    <option :value="tr.id" v-for="tr in transactions">{{ tr.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputYearCreate">Tahun</label>
                <select id="inputYearCreate" class="form-control" v-model="year">
                    <option :value="yer.id" v-for="yer in years">{{ yer.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="inputMonthCreate">Bulan</label>
                <select id="inputMonthCreate" class="form-control" v-model="month">
                    <option :value="mon.id" v-for="mon in months">{{ mon.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-primary">Filter</button>
            </div>
        </form>
        <bar-chart :chartdata="chartData" :options="chartOptions" v-if="isLoaded"/>
        <div class="table-responsive mt-2" v-if="dataResume.data.length > 0">
            <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                    <th scope="col">DATE</th>
                    <th scope="col">DESCRIPTION</th>
                    <th scope="col">TRANSACTION</th>
                    <th scope="col">CATEGORY</th>
                    <th scope="col">AMOUNT</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="finance, index in dataResume.data">
                    <td>{{ finance.created_at }}</td>
                    <td>{{ finance.description }}</td>
                    <td v-if="finance.transaction === 0 || finance.transaction === '0'">Debit</td>
                    <td v-else>Kredit</td>
                    <td>{{ finance.category }}</td>
                    <td style="text-align: right;">{{ indoCurrency(finance.amount) }}</td>
                </tr>
                <tr>
                    <th colspan="4" style="text-align: center;">TOTAL</th>
                    <td style="text-align: right;">{{ indoCurrency(dataResume.total) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-2" v-else>Data Resume Tidak Tersedia.</div>
    </div>
</template>

<script>
    import { EventBus } from '../app'
    import { unAuthorize } from '../unauthorize'

    export default {
        data: function () {
            return {
                chartData: null,
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                isLoaded: false,
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                transactions: [
                    {id: '0', text: 'Debit'},
                    {id: '1', text: 'Kredit'}
                ],
                months: [
                    {id: '0', text: 'Pilih Bulan'},
                    {id: '1', text: 'Januari'},
                    {id: '2', text: 'Februari'},
                    {id: '3', text: 'Maret'},
                    {id: '4', text: 'April'},
                    {id: '5', text: 'Mei'},
                    {id: '6', text: 'Juni'},
                    {id: '7', text: 'Juli'},
                    {id: '8', text: 'Agustus'},
                    {id: '9', text: 'September'},
                    {id: '10', text: 'Oktober'},
                    {id: '11', text: 'November'},
                    {id: '12', text: 'Desember'},
                ],
                years: [],
                data: [],
                dataResume: {
                    total: 0,
                    data: []
                },
                transaction: this.$route.query.transaction,
                month: this.$route.query.month,
                year: this.$route.query.year,
                index: 0
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.transaction = to.query.transaction
            this.month = to.query.month
            this.year = to.query.year
            this.getData()
            this.getDataResume()
            next()
        },
        methods: {
            searchData() {
                const params = {}
                if (this.transaction !== '0') {
                    params.transaction = this.transaction
                }
                if (this.month !== '0') {
                    params.month = this.month
                }
                if (this.year !== this.getCurrentYear()) {
                    params.year = this.year
                }
                this.$router.push({name: "admin.finances.dashboard", query: params})
            },
            getData() {
                this.isLoaded = false
                const headers = this.headers
                const params = {}
                if (this.transaction !== undefined) {
                    params.transaction = this.transaction
                } else {
                    this.transaction = '0'
                    params.transaction = this.transaction
                }
                if (this.month !== undefined) {
                    params.month = this.month
                } else {
                    this.month = '0'
                }
                if (this.year !== undefined && this.year !== this.getCurrentYear()) {
                    params.year = this.year
                } else {
                    this.year = this.getCurrentYear()
                }
                axios.get('/api/finances-dashboard', {params, headers})
                    .then((res) => {
                        this.data = res.data.data
                        var title = ''
                        if (this.transaction !== '0') {
                            title += 'Pemasukan'
                        } else {
                            title += 'Pengeluaran'
                        }
                        if (this.month !== '0') {
                            title += ' Bulan ' + this.months[parseInt(this.month)].text
                        }
                        if (this.year !== this.getCurrentYear()) {
                            title += ' Tahun ' + this.year
                        }
                        const chartData = {
                            labels: [],
                            datasets: [
                                {
                                    label: title,
                                    backgroundColor: '#c29856',
                                    data: []
                                }
                            ]
                        }

                        var formatter = new Intl.NumberFormat('id', {
                            style: 'currency',
                            currency: 'IDR',
                        });

                        for (let i = 0; i < this.data.length; i++) {
                            const data = this.data[i]
                            chartData.labels.push(`${data.category} (${formatter.format(data.amount)})`)
                            chartData.datasets[0].data.push(data.amount)
                        }
                        this.chartData = chartData
                        this.isLoaded = true

                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            getDataResume() {
                const headers = this.headers
                const params = {}
                params.resume = 1
                if (this.month !== undefined && this.month !== '0') {
                    params.month = this.month
                } else {
                    this.month = '0'
                }
                if (this.year !== undefined && this.year !== this.getCurrentYear()) {
                    params.year = this.year
                } else {
                    this.year = this.getCurrentYear()
                }
                axios.get('/api/finances-dashboard', {params, headers})
                    .then((res) => {
                        this.dataResume = res.data.data
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            indoCurrency(value) {
                var formatter = new Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'IDR',
                });
                return formatter.format(value)
            },
            getCurrentYear() {
                var d = new Date()
                return d.getFullYear()
            }
        },
        mounted() {
            var tahun = parseInt(this.getCurrentYear())
            for (var i = 0; i < 5; i++) {
                this.years.push({id: tahun, text: tahun})
                tahun--
            }
            this.getData()
            this.getDataResume()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
