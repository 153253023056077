import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const guardUserAuthenticated = (to, from, next) => {
    if (store.getters.token !== null) {
        toastr.error('Akses Ditolak karena Anda sudah Login', 'Haris Blog')
        next({ path: '/' })
    } else {
        next()
    }
}

const guardUserUnAuthenticated = (to, from, next) => {
    if (store.getters.token !== null) {
        next()
    } else {
        toastr.error('Akses Ditolak karena Anda sudah Login', 'Haris Blog')
        next({ path: '/' })
    }
}

import Login from './auth/Login'
import Home from './guest/Home'
import DetailBlog from './guest/DetailBlog'
import SearchBlog from './guest/SearchBlog'
import AboutMe from './guest/AboutMe'
import Blog from './guest/Blog'
import Portofolio from './guest/Portofolio'
import AdminCategories from './admin/AdminCategories'
import AdminTags from './admin/AdminTags'
import AdminSubscribers from './admin/AdminSubscribers'
import AdminContents from './admin/AdminContents'
import AdminCreateOrEditContent from './admin/AdminCreateOrEditContent'
import AdminBlogViews from './admin/AdminBlogViews'
import AdminVisitors from './admin/AdminVisitors'
import AdminPortfolios from './admin/AdminPortfolios'
import AdminCreateOrEditPortfolio from './admin/AdminCreateOrEditPortfolio'
import AdminFinances from './admin/AdminFinances'
import AdminCreateOrEditFinance from './admin/AdminCreateOrEditFinance'
import AdminDashboardFinance from './admin/AdminDashboardFinance'

const routes = [
    {path: '/login', name: 'auth.login', component: Login, beforeEnter: guardUserAuthenticated},
    {path: '/', name: 'guest.home', component: Home},
    {path: '/blog/:slug', name: 'guest.detailBlog', component: DetailBlog},
    {path: '/blog/search/:type/:value', name: 'guest.searchBlog', component: SearchBlog},
    {path: '/blog', name: 'guest.blog', component: Blog},
    {path: '/portofolio', name: 'guest.portofolio', component: Portofolio},
    {path: '/about-me', name: 'guest.aboutMe', component: AboutMe},
    {path: '/admin/categories', name: 'admin.categories', component: AdminCategories, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/tags', name: 'admin.tags', component: AdminTags, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/subscribers', name: 'admin.subscribers', component: AdminSubscribers, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/contents', name: 'admin.contents', component: AdminContents, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/contents/createOrEdit', name: 'admin.contents.createOrEdit', component: AdminCreateOrEditContent, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/blogViews', name: 'admin.blogViews', component: AdminBlogViews, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/visitors', name: 'admin.visitors', component: AdminVisitors, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/portfolios', name: 'admin.portfolios', component: AdminPortfolios, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/portfolios/createOrEdit', name: 'admin.portfolios.createOrEdit', component: AdminCreateOrEditPortfolio, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/finances', name: 'admin.finances', component: AdminFinances, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/finances/createOrEdit', name: 'admin.finances.createOrEdit', component: AdminCreateOrEditFinance, beforeEnter: guardUserUnAuthenticated},
    {path: '/admin/finances/dashboard', name: 'admin.finances.dashboard', component: AdminDashboardFinance, beforeEnter: guardUserUnAuthenticated},
]

export default new Router({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
