import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: VueCookies.get('token')
    },
    mutations: {
        setToken(state, payload) {
            if (payload === null) {
                state.token = null
            } else {
                state.token = 'Bearer ' + payload
            }
        }
    },
    actions: {
        //
    },
    getters: {
        token(state) {
            return state.token
        }
    }
})
