<template>
    <div class="card mt-5">
        <img :src="'/storage/' + portfolio.portfolio_images[0].file" class="card-img-top fit_image" width="200" height="200" alt="Image">
        <div class="card-body">
            <h5 class="card-title">{{ portfolio.title }}</h5>
            <button type="button" class="btn btn-outline-primary" v-on:click="onClickModal">
                Lihat Gambar
            </button>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../app";

    export default {
        data: function () {
            return {
                //
            }
        },
        props: {
            portfolio: Object,
        },
        methods: {
            truncate(value, limit) {
                if (value.length > limit) {
                    value = value.substring(0, (limit - 3)) + '...';
                }

                return value
            },
            onClickModal() {
                EventBus.$emit('onCLickModalPortfolio', this.portfolio)
            }
        },
        mounted() {

        }
    }
</script>
