<template>
    <div style="background-color: white;">
        <div class="blog_image" v-if="content.file !== undefined || content.file !== null">
            <a :href="'/blog/' + content.slug">
                <img width="1500" height="720"
                     :src="`/storage/${content.file}`"
                     :alt="content.title" class="img-fluid fit_image">
            </a>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12 p-4" v-if="!isLoaded">
                    <div class="d-flex align-items-center">
                        <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                        <strong>Loading...</strong>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12 p-4" v-if="isLoaded">
                    <div class="blog_content">
                        <div class="blog_header pt-3 pb-3">
                            <div class="blog_header_category">
                                <a href="/">Blog</a>
                                <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>
                                <router-link :to="{ name: 'guest.searchBlog', params: {type: 'category', value: content.category.slug} }">{{ content.category.title }}</router-link>
                            </div>
                            <h2 class="font-italic">
                                <a :href="'/blog/' + content.slug">{{ content.title }}</a>
                            </h2>
                            <div class="blog_meta">
                                <router-link :to="{ name: 'guest.searchBlog', params: {type: 'createdAt', value: convertUTCDateOnly(content.created_at)} }" class="font-italic">{{ convertUTC(content.created_at) }}</router-link>
                                <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>
                                <router-link :to="{ name: 'guest.aboutMe' }" class="font-italic">Oleh Haris Miftahul Hudha</router-link>
<!--                                <span style="color: #aab0b4; margin-left: 5px; margin-right: 5px;">•</span>-->
<!--                                <router-link :to="{ name: 'guest.detailBlog', params: {slug: 'paradigma-anak-tunggal'}, query: {goTo: 'comments'} }" class="font-italic">7 Comments</router-link>-->
                            </div>
                        </div>
                        <div class="blog_body">
                            <span v-html="content.body"></span>
                        </div>
                        <div class="blog_footer">
                            <div class="row">
                                <div class="col-md-7 col-sm-12">
                                    <p>
                                        <span v-for="tag in content.tags" class="label" v-on:click="searchBlog('tag', tag.slug)">{{ tag.title }}</span>
                                    </p>
                                </div>
                                <div class="col-md-5 col-sm-12">
                                    <p class="text-right font-italic">
                                        Bagikan :
                                        <social-sharing :url="'https://harismiftahulhudha.co/blog/' + content.slug"
                                                        :title="content.title"
                                                        :description="shareBody(content.body)"
                                                        :hashtags="convertArrayToString(content.tags)"
                                                        twitter-user="vuejs"
                                                        :media="'storage/' + content.file"
                                                        inline-template>
                                            <span class="social">
                                                <network network="facebook">
                                                    <i class="fa fa-facebook-square"></i>
                                                </network>
                                                <network network="linkedin">
                                                    <i class="fa fa-linkedin-square"></i>
                                                </network>
                                                <network network="pinterest">
                                                    <i class="fa fa-pinterest"></i>
                                                </network>
                                                <network network="telegram">
                                                    <i class="fa fa-telegram"></i>
                                                </network>
                                                <!--                                                <network network="twitter">-->
                                                <!--                                                    <i class="fa fa-twitter"></i>-->
                                                <!--                                                </network>-->
                                                <network network="whatsapp">
                                                    <i class="fa fa-whatsapp"></i>
                                                </network>
                                            </span>
                                        </social-sharing>
                                    </p>
                                </div>
                            </div>
                            <hr style="background: #eeeeee;">
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4 mb-4" v-if="!isLoadedRelate">
                        <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                        <strong>Loading...</strong>
                    </div>
                    <div class="related_post" v-if="isLoadedRelate">
                        <p class="title font-italic">Postingan Terkait</p>
                        <div class="row" v-if="relatedContents.length === 0">
                            <p class="font_lora mt-2" style="display: block; text-align: center; width: 100%;">Postingan Terkait tidak ditemukan!</p>
                        </div>
                        <div class="row" v-else>
                            <div class="col-lg-4 col-md-4 col-sm-12" v-for="relate in relatedContents">
                                <a :href="'/blog/' + relate.slug">
                                    <img :src="`/storage/${relate.file}`" :alt="relate.title" class="img-fluid fit_image">
                                    <p class="row_title"><span class="font-italic">{{ relate.title }}</span><br><span class="row_subtitle">{{ convertUTC(relate.created_at) }}</span></p>
                                </a>
                            </div>
                        </div>
                        <hr style="background: #eeeeee;">
                    </div>
                    <vue-disqus v-if="isLoaded" shortname="harismiftahulhudha" :identifier="`'${content.slug}'`" :url="`https://harismiftahulhudha.co/blog/${content.slug}`" :api_key="disqusToken"></vue-disqus>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 p-4">
                    <sidebar-detail-blog></sidebar-detail-blog>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {unAuthorize} from "../unauthorize";
    import moment from 'moment'

    export default {
        data: function () {
            return {
                loop15: 15,
                loop5: 5,
                loop3: 3,
                content: {
                    category: {},
                    tags: []
                },
                relatedContents: [],
                slug: this.$route.params.slug,
                disqusToken: process.env.MIX_DISQUS_KEY,
                isLoaded: false,
                isLoadedRelate: false
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.slug = to.params.slug
            this.isLoaded = false
            this.getData()
            this.getRelatedContents()
            next()
        },
        methods: {
            detailBlog(slug) {
                this.$router.push({ name: 'guest.detailBlog', params: {slug: slug} })
            },
            searchBlog(type, value) {
                this.$router.push({ name: 'guest.searchBlog', params: {type: type, value: value} })
            },
            getData() {
                axios.get('/api/guestContents/' + this.slug)
                    .then((res) => {
                        this.content = res.data.data
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            convertArrayToString(tags) {
                var join = ''
                for (var i = 0; i < tags.length; i++) {
                    join += tags[i].title
                    if (i < tags.length - 1) {
                        join += ','
                    }
                }
                return join
            },
            shareBody(body) {
                if (body !== undefined) {
                    var arrayBody = body.split('</p>')
                    console.log(arrayBody[0].substr(3, arrayBody[0].length))
                    return arrayBody[0].substr(3, arrayBody[0].length)
                } else {
                    return body
                }
            },
            getRelatedContents() {
                const params = {
                    related: 1,
                    slug: this.slug
                }
                axios.get('/api/guestContents', {params})
                    .then((res) => {
                        this.relatedContents = res.data.data
                        this.isLoadedRelate = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            convertUTC(date) {
                moment.locale('id');
                var dateFormat = 'MMMM, Do YYYY';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            },
            convertUTCDateOnly(date) {
                moment.locale('id');
                var dateFormat = 'YYYY-M-D';
                var testDateUtc = moment.utc(date);
                var localDate = testDateUtc.local();
                return localDate.format(dateFormat)
            }
        },
        mounted() {
            this.getData()
            this.getRelatedContents()
        }
    }
</script>
