<template>
    <div class="sidebar">
        <sidebar-blog-about-me/>
        <sidebar-blog-subscribe-home/>
        <sidebar-blog-recent-posts :total-data="5"/>
        <sidebar-blog-categories/>
        <sidebar-blog-popular-posts :total-data="5"/>
        <sidebar-blog-tags/>
        <sidebar-blog-search/>
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                //
            }
        },
        methods: {
        },
        mounted() {
            //
        }
    }
</script>
