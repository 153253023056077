<template>
    <div class="about">
        <p class="title font-italic">Tentang Saya</p>
        <hr class="hr">
        <img
            src="/image/personal_picture.png"
            alt="About Me" width="100%" class="img-fluid mt-3">
        <p class="body">
            Halo, nama saya adalah <strong>Haris Miftahul Hudha</strong>. Saya seorang Software Developer yang tinggal di Surabaya. Ini adalah blog saya, tempat saya memposting tentang pemrograman, cerita, pendapat pribadi tentang berita terbaru. Jangan pernah lewatkan postingan yang mungkin berguna bagi anda.
        </p>
        <hr class="hr_full">
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                //
            }
        },
        methods: {
            //
        },
        mounted() {
            //
        }
    }
</script>
