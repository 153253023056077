<template>
    <section id="content" style="background-color: white;">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12 p-4">
                    <div class="row">
                        <div class="col-md-6 mt-5" v-if="!isLoaded">
                            <div class="d-flex align-items-center">
                                <div class="spinner-border mr-3" role="status" aria-hidden="true"></div>
                                <strong>Loading...</strong>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="isLoaded && data.length === 0">
                            <h3 class="font_lora mt-5">Portofolio Belum Tersedia</h3>
                        </div>
                        <div class="col-md-6" v-for="portfolio in data" v-if="isLoaded && data.length > 0">
                            <portofolio-row :portfolio="portfolio"/>
                        </div>
                        <div class="modal fade" id="modalSlide" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                                <div class="modal-content">
                                    <div class="modal-body mb-0 p-0">
                                        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item" v-bind:class="{ active: index === 0 }" v-for="image, index in portfolio.portfolio_images">
                                                    <img :src="'/storage/' + image.file" class="d-block w-100" alt="Image">
                                                </div>
                                            </div>
                                            <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </div>
                                        <p v-html="portfolio.description" class="p-2"></p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4 text-center" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row" style="justify-content: center;" v-if="pagination.lastPage > 1">
                            <nav aria-label="Page Haris Blog">
                                <ul class="pagination">
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.firstPage)" aria-label="Previous">
                                            <i class="fa fa-step-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.prevPage)" aria-label="Previous">
                                            <i class="fa fa-backward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{ active: pagination.currentPage === page }" v-for="page in pagination.pages">
                                        <a class="page-link" v-on:click="getPagination(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.nextPage)" aria-label="Next">
                                            <i class="fa fa-forward"></i>
                                        </a>
                                    </li>
                                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                                        <a class="page-link" v-on:click="getPagination(pagination.lastPage)" aria-label="Next">
                                            <i class="fa fa-step-forward"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 p-4">
                    <sidebar-blog></sidebar-blog>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { EventBus } from "../app"
    import {paginate} from "../pagination";
    import {unAuthorize} from "../unauthorize";
    import {convertUTCDateToLocalDate} from "../convertUTC";

    export default {
        data: function () {
            return {
                pagination: {
                    currentPage: 1,
                    firstPage: 1,
                    lastPage: 0,
                    nextPage: 0,
                    prevPage: 0,
                    offsetPage: 0,
                    pages: []
                },
                page: this.$route.query.page,
                data: [],
                portfolio: {
                    description: '',
                    portfolio_images: []
                },
                isLoaded: false,
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.page = to.query.page
            this.getData()
            next()
        },
        methods: {
            getPagination(page) {
                const params = {}
                if (page === 1 || page === '1') {
                    this.page = undefined
                } else {
                    this.page = page
                    params.page = page
                }
                this.$router.push({name: "guest.home", query: params})
            },
            getData() {
                const params = {}
                if (this.page !== undefined) {
                    params.page = this.page
                }
                axios.get('/api/guestPortfolios', {params})
                    .then((res) => {
                        this.data = res.data.data
                        this.isLoaded = true
                        if (this.data.length === 0) {
                            this.resetPagination()
                        } else {
                            this.pagination.currentPage = res.data.current_page
                            this.pagination.lastPage = res.data.last_page
                            if (res.data.prev_page_url !== null) {
                                this.pagination.prevPage = res.data.prev_page_url.split("page=")[1]
                            }
                            if (res.data.next_page_url !== null) {
                                this.pagination.nextPage = res.data.next_page_url.split("page=")[1]
                            }
                            this.pagination.pages = paginate(res.data.total, this.pagination.currentPage, res.data.per_page, 5).pages
                        }
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            truncate(value, limit) {
                if (value.length > limit) {
                    value = value.substring(0, (limit - 3)) + '...';
                }

                return value
            },
            onClickModal() {
                EventBus.$on('onCLickModalPortfolio', data => {
                    this.portfolio = data
                    $('#modalSlide').modal('show')
                })
            },
        },
        mounted() {
            this.getData()
            $('.carousel').carousel({
                interval: 4000
            })
            this.onClickModal()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
