<template>
    <div class="p-5 background_white font_lora">
        <h3 class="mb-4">Visitors</h3>
        <line-chart :chartdata="chartData" :options="chartOptions" v-if="isLoaded"/>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                    <th scope="col">IP</th>
                    <th scope="col">BROWSER</th>
                    <th scope="col">OS</th>
                    <th scope="col">COUNTRY NAME</th>
                    <th scope="col">REGION NAME</th>
                    <th scope="col">CITY</th>
                    <th scope="col">TIME ZONE</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="visitor in data">
                    <td>{{ visitor.ip }}</td>
                    <td>{{ visitor.browser }}</td>
                    <td>{{ visitor.os }}</td>
                    <td>{{ visitor.country_name }}</td>
                    <td>{{ visitor.region_name }}</td>
                    <td>{{ visitor.city }}</td>
                    <td>{{ visitor.time_zone }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="row" style="justify-content: center;" v-if="pagination.lastPage > 1">
            <nav aria-label="Page Haris Blog">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.firstPage)" aria-label="Previous">
                            <i class="fa fa-step-backward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.firstPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.prevPage)" aria-label="Previous">
                            <i class="fa fa-backward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{ active: pagination.currentPage === page }" v-for="page in pagination.pages">
                        <a class="page-link" v-on:click="getPagination(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.nextPage)" aria-label="Next">
                            <i class="fa fa-forward"></i>
                        </a>
                    </li>
                    <li class="page-item" v-bind:class="{disabled: pagination.currentPage === pagination.lastPage}">
                        <a class="page-link" v-on:click="getPagination(pagination.lastPage)" aria-label="Next">
                            <i class="fa fa-step-forward"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../app'
    import { unAuthorize } from "../unauthorize";

    export default {
        data: function () {
            return {
                pagination: {
                    currentPage: 1,
                    firstPage: 1,
                    lastPage: 0,
                    nextPage: 0,
                    prevPage: 0,
                    offsetPage: 0,
                    pages: []
                },
                data: [],
                chartData: null,
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                isLoaded: false,
                headers: {
                    Authorization: this.$cookies.get('token'),
                },
                page: this.$route.query.page,
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.page = to.query.page
            this.getData()
            next()
        },
        methods: {
            getPagination(page) {
                const params = {}
                if (page === 1 || page === '1') {
                    this.page = undefined
                } else {
                    this.page = page
                    params.page = page
                }
                this.$router.push({name: "admin.visitors", query: params})
            },
            getData() {
                const headers = this.headers
                const params = {}
                if (this.page !== undefined) {
                    params.page = this.page
                }
                axios.get('/api/visitors-list', {params, headers})
                    .then((res) => {
                        this.data = res.data.data
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            },
            getVisitors() {
                const headers = this.headers
                axios.get('/api/visitors', {headers})
                    .then((res) => {
                        const chartData = {
                            labels: [],
                            datasets: [
                                {
                                    label: 'Visitors',
                                    backgroundColor: '#c29856',
                                    data: []
                                }
                            ]
                        }

                        for (let i = 0; i < res.data.length; i++) {
                            const data = res.data[i]
                            chartData.labels.push(data.date_format)
                            chartData.datasets[0].data.push(data.total)
                        }
                        this.chartData = chartData
                        this.isLoaded = true
                    })
                    .catch((err) => {
                        unAuthorize(err)
                    })
            }
        },
        mounted() {
            this.getVisitors()
            this.getData()
            EventBus.$emit('checkMenu', this.$route.path)
        }
    }
</script>
